import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Renderer2, Input } from '@angular/core';
import { ChatService, IChatContact, IChatConversation } from './chat.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BaseComponent } from '../../@core/BaseComponent/base.component';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as moment from 'moment';
import { AudioRecordingService } from '../../@core/Services/recording.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html'
})
export class ChatComponent extends BaseComponent implements OnDestroy {

  @ViewChild('scroll') scrollRef: PerfectScrollbarComponent;

  contacts: IChatContact[];
  conversations: IChatConversation[];
  // currentUserId = 1;

  selectedConversation: any;

  contacts$: Observable<IChatContact[]>;

  searchTerms = new Subject<string>();
  searchKeyword = '';
  message = '';
  currentUserId: number = 0;

  isAudioRecording = false;
  audioRecordedTime;
  audioBlobUrl;
  getaudioBlobUrl;
  audioName;
  audioBlob;
  getaudioBlob;
  blobURL;

  @Input() ppdid: number = 0;
  @Input() isGroup: boolean = true;
  isAudio: boolean = false;

  constructor(private chatService: ChatService, private notifications: NotificationsService, private changeDetectorRef: ChangeDetectorRef, private renderer: Renderer2,
    private audioRecordingService: AudioRecordingService, private sanitizer: DomSanitizer) {
    super();
    this.audioRecordingService.recordingFailed().subscribe(() => {

      this.isAudioRecording = false;
      this.changeDetectorRef.detectChanges();
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.audioRecordedTime = time;
      this.changeDetectorRef.detectChanges();
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {

      this.audioBlob = data.blob;
      this.audioName = data.title;

      this.blobURL = URL.createObjectURL(data.blob);
      this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));

      this.changeDetectorRef.detectChanges();

      if (this.audioBlob) {

        let data: any = {
          id: 0,
          msgBy: localStorage.getItem('UserID'),
          chatMsg: this.message ? this.message : '',
          groupID: this.selectedConversation.ppdid,

        }

        var reader = new FileReader();
        reader.readAsDataURL(this.audioBlob);
        reader.onloadend = async () => {

          var base64data: any = reader.result;
          data.audioBlob = base64data.split('data:audio/wav;base64,')[1];

          await this.baseService.post('Chat', 'save', data, 'apiURL').then(o => {

            if (o && o.id > 0) {
              this.clearAudioRecordedData();
              this.message = '';
              this.getChatList(this.selectedConversation.ppdid);

            }

          });
        }
      }
    });
  }

  async ngOnInit() {

    this.renderer.addClass(document.body, 'no-footer');
    this.currentUserId = Number(localStorage.getItem('UserID'));
    // this.getContacts();

    // this.contacts$ = this.searchTerms.pipe(
    //   debounceTime(300),
    //   distinctUntilChanged(),
    //   switchMap((term: string) => this.chatService.searchContacts(this.currentUserId, term)),
    // );

    if (this.isGroup)
      await this.getGroups();
    else
      await this.getChatList(this.ppdid)
    // await this.getChatList();

  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'no-footer');
  }


  search(term: string): void {
    this.searchKeyword = term;
    this.searchTerms.next(term);
  }


  getContacts(): void {
    this.chatService.getContacts()
      .subscribe(contacts => {
        this.contacts = contacts;
        this.getConversations();
      });
  }

  getConversations(): void {
    this.chatService.getConversations(this.currentUserId)
      .subscribe(conversations => {
        this.conversations = conversations;
        this.selectedConversation = this.conversations[0];
        this.changeDetectorRef.detectChanges();
        if (this.scrollRef) {
          this.scrollRef.directiveRef.scrollToBottom();
        }
      });
  }
  selectConversation(conversationId: number): void {
    this.selectedConversation = this.conversations.find(x => x.id === conversationId);
    if (this.scrollRef) {
      setTimeout(() => { this.scrollRef.directiveRef.scrollToBottom(); }, 100);
    }
  }


  getOtherUser(users: number[]): IChatContact {
    const otherId = users.find(x => x !== this.currentUserId);
    return this.contacts.find(x => x.id === otherId);
  }
  getUser(id: number): IChatContact {
    if (id === this.currentUserId) {
      return {
        id,
        title: 'Sarah Kortney',
        img: '/assets/img/profiles/l-1.jpg',
        date: '5 minutes ago'
      };
    }
    return this.contacts.find(x => x.id === id);
  }

  async sendMessage() {

    if (this.message.length > 0 && this.audioRecordedTime && this.isAudioRecording) {

      await this.stopAudioRecording();

    }
    else if (this.message.length === 0 && this.audioRecordedTime && this.isAudioRecording) {

      await this.stopAudioRecording();

    }
    else if (this.message.length > 0) {

      let data = {
        id: 0,
        msgBy: localStorage.getItem('UserID'),
        chatMsg: this.message,
        groupID: this.selectedConversation.ppdid,

      }

      await this.baseService.post('Chat', 'save', data, 'apiURL').then(o => {

        if (o && o.id > 0) {

          this.getChatList(this.selectedConversation.ppdid);
        }

      });

      this.message = '';
    } else {

    }
  }

  messageInputKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') { this.sendMessage(); }
  }

  getCurrentTime(): string {
    const now = new Date();
    return this.pad(now.getHours(), 2) + ':' + this.pad(now.getMinutes(), 2);
  }

  // tslint:disable-next-line:variable-name
  pad(number, length): string {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  // Api
  grouplist: any[] = [];
  async getGroups() {
    let val = 'UserID=' + localStorage.getItem('UserID') + '&LocationID=' + localStorage.getItem('LocationID');
    await this.get('Chat', 'getGroups', val, 'apiURL').then((i: any) => {
      if (i && i.length && i.length > 0) {
        this.grouplist = i;

        let currentChat: any = localStorage.getItem('currentChat');

        currentChat = JSON.parse(currentChat);
        if (currentChat) {

          this.selectedConversation = currentChat.group;
        } else {

          this.selectedConversation = this.grouplist[0];
        }
        if (this.selectedConversation && this.selectedConversation.ppdid) {
          this.getChatList(this.selectedConversation.ppdid);
        }
      }
    })
  };

  chatlist: any[] = [];
  async getChatList(groupid) {
    if (Number(groupid) > 0) {
      let val = 'tType=0&' + 'UserID=' + localStorage.getItem('UserID') + '&GroupID=' + groupid;
      await this.get('Chat', 'getChatList', val).then((i: any) => {

        if (i && i.length && i.length > 0) {
          i.map(u => {
            if (u.createDateTime) {
              u.date = moment(u.createDateTime).format('DD-MMM-YYYY HH:mm')
            }
          })

          this.chatlist = i;
          if (this.isGroup) {
            this.selectedConversation.message = i;
            setTimeout(() => {
              if (this.scrollRef) {
                this.scrollRef.directiveRef.scrollToBottom(0, 100)
              }
            }, 0);
          }
          else {

            this.selectedConversation = {};
            this.selectedConversation.ppdid = this.ppdid;
            this.selectedConversation.message = i;
          }

        }
      })
    }
  };

  async changeGroup(item) {

    if (item && item.ppdid) {

      this.selectedConversation = this.grouplist.filter(i => i.ppdid === Number(item.ppdid))[0];
      if (this.selectedConversation && this.selectedConversation.ppdid) {
        this.getChatList(this.selectedConversation.ppdid);
      }
    }
  }

  // Audio Recording
  startAudioRecording() {
    if (!this.isAudioRecording) {
      this.isAudioRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortAudioRecording() {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopAudioRecording() {

    if (this.isAudioRecording) {
      this.audioRecordingService.stopRecording();
      this.isAudioRecording = false;
    }
  }

  clearAudioRecordedData() {
    this.audioBlobUrl = undefined;
    this.audioBlob = undefined;
    this.isAudioRecording = false;
  }

  downloadAudioRecordedData() {
    this._downloadFile(this.audioBlob, 'audio/mp3', this.audioName);
  }

  // ngOnDestroy(): void {
  //   this.abortAudioRecording();
  // }

  _downloadFile(data: any, type: string, filename: string): any {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  audioObj: any
  async getAudio(id) {

    if (this.selectedConversation.ppdid > 0 && id > 0) {

      let val = 'id=' + this.selectedConversation.ppdid + id;
      this.loader.startBackground();
      await this.postRespone('Chat', 'getAudioFile', val, 'apiURL').then(i => {
        this.loader.stopBackground();
        if (i) {
          let blob = new Blob([i], { type: "audio/mp3" });

          if (blob) {

            var audioELement: any = document.getElementById(this.selectedConversation.ppdid + id);

            if (audioELement) {

              audioELement.innerHTML = `<audio #audioControl class="mb-2" controls>
              <source src="${URL.createObjectURL(blob)}" type="audio/mp3">
          </audio>`
            }
          }
          this.isAudio = false;
          this.audioObj = i;
        } else {
          this.audioObj = undefined;
        }
      })
    }
  }

  onClick() {

  }
}
