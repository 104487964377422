// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


// This Module's Components
import { OrderLookupComponent } from './order-lookup.component';
import { BizDatatableModule } from '../biz-datatable/biz-datatable.module';
import { PagesContainersModule } from '../../../../../containers/pages/pages.containers.module';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, BizDatatableModule, PagesContainersModule

    ],
    declarations: [
        OrderLookupComponent,
    ],
    exports: [
        OrderLookupComponent,
    ]
})
export class OrderLookupModule {

}
