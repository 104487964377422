import { Injectable, Injector, Input } from "@angular/core";

@Injectable()
export abstract class ControlBase {
    private _index: number = -1;
    @Input()
    get index(): any {
        return this._index;
    }

    set index(v: any) {
        this._index = v;
    }
}

export const Enum = {
    injector: <Injector>{},
    Datatable_ControlType: {
        Text: 1,
        DropDown: 2,
        Date: 3,
        Number: 4,
        Button: 5,
        DropDownLister: 6,
        Checkbox: 7,
        InputLister: 8,
        InputSelect: 9,
        InputSelectLister: 10,
        DataList: 11
    },

    DataTable_Type: {
        InputTable: 1,
        DisplayTable: 2,
        ActionTable: 3,
        SingleSelection: 4,
        MultiSelection: 5,
    },

    ColumnMode: {
        standard: "standard",
        flex: "flex",
        force: "force"
    }
}

export interface MapConfiguration {
    field: string;
    mapField: string;
}

export const mapLeatherConfigs = {
    leather: <MapConfiguration[]>[{
        field: "leather",
        mapField: "leather"
    }
    ],
    leatherOrder: <MapConfiguration[]>[
        // {
        //     field: "orderPcs",
        //     mapField: "qty"
        // },
        {
            field: "style",
            mapField: "style"
        }
    ],
    leatherSalesInquiry: <MapConfiguration[]>[{
        field: "fkLeatherId",
        mapField: "id"
    }
    ],

    leatherSalesInquiryDetail: <MapConfiguration[]>[{
        field: "fkLMID",
        mapField: "id"
    }
    ],

    leatherPurchaseOrderDetail: <MapConfiguration[]>[
        {
            field: "fkLMID",
            mapField: "fkLeatherId"
        },
        {
            field: "leather",
            mapField: "leather"
        },
        {
            field: "sqft",
            mapField: "sqft"
        },
        {
            field: "price",
            mapField: "rate"
        },
        {
            field: "skinSize",
            mapField: "size"
        },
    ],
    leatherPurchaseRecvdDetail: <MapConfiguration[]>[
        {
            field: "ordered",
            mapField: "orderSqFt"
        },
        {
            field: "already",
            mapField: "alreadyRecevied"
        },
        {
            field: "leatherid",
            mapField: "id"
        },
    ],
    leatherIssueMapping: <MapConfiguration[]>[
        {
            field: "fkLeatherId",
            mapField: "fklmid"
        },
        {
            field: "leather",
            mapField: "leather"
        },
        {
            field: "qtyReq",
            mapField: "qtyReq"
        },
        {
            field: "alreadyIssued",
            mapField: "alreadyIssued"
        },
    ],
    leatherIssueLotMapping: <MapConfiguration[]>[
        {
            field: "qtySf",
            mapField: "qtySf"
        },
        {
            field: "amount",
            mapField: "amount"
        },
        {
            field: "pcs",
            mapField: "pcs"
        },
        {
            field: "rolls",
            mapField: "rolls"
        },
    ],
    SMPMapping: <MapConfiguration[]>[
        {
            field: "ppd",
            mapField: "ppd"
        },
        {
            field: "model",
            mapField: "model"
        },
        {
            field: "lthr",
            mapField: "lthr"
        },
        {
            field: "stkQty",
            mapField: "qty"
        },
        {
            field: "qty",
            mapField: "qty"
        },
        {
            field: "rate",
            mapField: "rate"
        },
    ],
    GPDetailMapping: <MapConfiguration[]>[
        {
            field: "fkItemId",
            mapField: "fkItem"
        },
        {
            field: "fkLotNo",
            mapField: "fklotNo"
        },
        {
            field: "sqft",
            mapField: "sqft"
        },
        {
            field: "rate",
            mapField: "rate"
        },
        {
            field: "editRate",
            mapField: "rate"
        },
        {
            field: "pcs",
            mapField: "pieces"
        },
        {
            field: "rolls",
            mapField: "roll"
        },
    ],

    ORDERSMapping: <MapConfiguration[]>[
        // {
        //     field: "id",
        //     mapField: "id"
        // },
    ],

}

export const ApiController = {
    propertyFeatureController: "PropertyFeature",
    PropertyScheduleController: "SubProjectsSchedule",
    subProjectShedule: "SubProjectShedule",
    subProjectsFeature: "SubProjectsFeature",
    reportCategory: "ReportCategory",
    reportDtl: "ReportDetail",
    reportModule: "ReportModule",
    reportParams: "ReportParameter",
    reportSelectionFormula: "ReportSelectionFormula",
    reportSetup: "ReportSetup",
    reportSP: "ReportSP",
}