<!--component html goes here -->
<label *ngIf="design === 1" class="form-group has-float-label">
    <div
        [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext  ? 'border-outline-invalid invalid' : 'border-outline-valid'">
        <ng-select #ngSelect [loading]="isloading" class="biz-ngSelect" [placeholder]="placeholder" [(ngModel)]="value"
            (clear)="clear()" (remove)="remove()" (change)="changeValue($event)" (keyup)="keyup($event.target.value)"
            [disabled]="disabled" (keydown.enter)="enter($event.target.value, $event.keyCode)">
            <ng-option *ngFor="let item of _data" [value]="item[valueMember]">{{item[displayMember]}}</ng-option>
        </ng-select>
    </div>
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;
        z-index: 4;
        margin-top: -23px;
        margin-right: 20px;">
        <span class="tooltipss" type="button">
            <i class="simple-icon-exclamation" aria-hidden="true"></i>
        </span>
    </span>
    <span for="texto">{{caption}}</span>
</label>

<label *ngIf="design === 2" class="form-group has-float-label">
    <div
        [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext  ? 'border-outline-invalid invalid' : 'border-outline-valid'">
        <ng-select #ngSelect [loading]="isloading" class="biz-ngSelect" [placeholder]="placeholder" [(ngModel)]="value"
            (clear)="clear()" (remove)="remove()" (keyup)="keyup($event.target.value)" (change)="changeValue($event)"
            [disabled]="disabled" (keydown.enter)="enter($event.target.value, $event.keyCode)">
            <ng-option *ngFor="let item of _data" [value]="item[valueMember]">{{item[displayMember]}}</ng-option>
        </ng-select>
    </div>
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;
        z-index: 4;
        margin-top: -23px;
        margin-right: 20px;">
        <span class="tooltipss" type="button">
            <i class="simple-icon-exclamation" aria-hidden="true"></i>
        </span>
    </span>
    <span for="texto">{{caption}}</span>
</label>
<label *ngIf="design === 6" class="form-group has-float-label">
    <div
        [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext  ? 'border-outline-invalid invalid' : 'border-outline-valid'">
        <ng-select #ngSelect [loading]="isloading" class="biz-ngSelect" [placeholder]="placeholder" [(ngModel)]="value"
        (remove)="remove()" (keyup)="keyup($event.target.value)" (change)="changeValue($event)"
            [disabled]="disabled" (keydown.enter)="enter($event.target.value, $event.keyCode)">
            <ng-option *ngFor="let item of _data" [value]="item[valueMember]">{{item[displayMember]}}</ng-option>
        </ng-select>
    </div>
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;
        z-index: 4;
        margin-top: -23px;
        margin-right: 20px;">
        <span class="tooltipss" type="button">
            <i class="simple-icon-exclamation" aria-hidden="true"></i>
        </span>
    </span>
    <span for="texto">{{caption}}</span>
</label>

<!-- Multi -->
<label *ngIf="design === 7" class="form-group has-float-label">
    <div
        [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext  ? 'border-outline-invalid invalid' : 'border-outline-valid'">
        <ng-select [multiple]="true" #ngSelect [loading]="isloading" class="biz-ngSelect" [placeholder]="placeholder" [(ngModel)]="value"
            (remove)="remove()" (keyup)="keyup($event.target.value)" (change)="changeValue($event)"
            [disabled]="disabled" (keydown.enter)="enter($event.target.value, $event.keyCode)">
            <ng-option *ngFor="let item of _data" [value]="item[valueMember]">{{item[displayMember]}}</ng-option>
        </ng-select>
    </div>
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;
        z-index: 4;
        margin-top: -23px;
        margin-right: 20px;">
        <span class="tooltipss" type="button">
            <i class="simple-icon-exclamation" aria-hidden="true"></i>
        </span>
    </span>
    <span for="texto">{{caption}}</span>
</label>

<!-- With Lookup -->
<label *ngIf="design === 3" class="form-group has-float-label">
    <div style="    display: flex;
    border: 1px solid #d7d7d7;
    border-radius: 5px;" [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty ||
        controlEl.touched)) || this.dirtytext ? 'border-outline-invalid invalid' : 'border-outline-valid'">
        <ng-select #ngSelect [loading]="isloading" style=" width: 100%;" class="biz-ngSelectlister"
            [placeholder]="placeholder" [(ngModel)]="value" (clear)="clear()" (remove)="remove()"
            (keyup)="keyup($event.target.value)" (keydown.enter)="enter($event.target.value, $event.keyCode)"
            [disabled]="disabled" (change)="changeValue($event)">
            <ng-option *ngFor="let item of _data" [value]="item[valueMember]">{{item[displayMember]}}</ng-option>
        </ng-select>
        <span style="padding-right: 5px;padding-top: 5px;cursor: pointer;background: #fff;
        border-radius: 5px;" (click)="openLister()"><i class="simple-icon-magnifier"></i></span>
        <span *ngIf="isButton" style="padding-right: 5px;padding-top: 5px;cursor: pointer;background: #fff;
        border-radius: 5px;" (click)="ButtonClick()"><i class="iconsminds-add"></i></span>
    </div>
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;
        z-index: 4;
        margin-top: -23px;
        margin-right: 20px;">
        <span class="tooltipss" type="button">
            <i class="simple-icon-exclamation" aria-hidden="true"></i>
        </span>
    </span>
    <span for="texto">{{caption}}</span>
</label>

<!-- With Lookup // 3 word // Enter -->
<label *ngIf="design === 5" class="form-group has-float-label">
    <div style="    display: flex;
    border: 1px solid #d7d7d7;
    border-radius: 5px;" [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty ||
        controlEl.touched)) || this.dirtytext ? 'border-outline-invalid invalid' : 'border-outline-valid'">
        <ng-select #ngSelect [loading]="isloading" style=" width: 100%;" class="biz-ngSelectlister"
            [placeholder]="placeholder" [(ngModel)]="value" (clear)="clear()" (remove)="remove()"
            (keyup)="keyup($event.target.value)" [disabled]="disabled"
            (keydown.enter)="enter($event.target.value, $event.keyCode)" (change)="changeValue($event)">
            <ng-option *ngFor="let item of _data" [value]="item[valueMember]">{{item[displayMember]}}</ng-option>
        </ng-select>
        <span style="padding-right: 5px;padding-top: 5px;cursor: pointer;background: #fff;
        border-radius: 5px;" (click)="openLister()"><i class="simple-icon-magnifier"></i></span>
        <span *ngIf="isButton" style="padding-right: 5px;padding-top: 5px;cursor: pointer;background: #fff;
        border-radius: 5px;" (click)="ButtonClick()"><i class="iconsminds-add"></i></span>
    </div>
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;
        z-index: 4;
        margin-top: -23px;
        margin-right: 20px;">
        <span class="tooltipss" type="button">
            <i class="simple-icon-exclamation" aria-hidden="true"></i>
        </span>
    </span>
    <span for="texto">{{caption}}</span>
</label>

<div *ngIf="design === 4" style="display: flex;
    border: none;
    background: transparent;
    margin-top: -5px;
    border-radius: 0pxpx;">
    <ng-select #ngSelect [loading]="isloading" style=" width: 100%;" class="biz-ngSelectlister-grid"
        [placeholder]="placeholder" [(ngModel)]="value" (clear)="clear()" (remove)="remove()"
        (keyup)="keyup($event.target.value)" [disabled]="disabled" (change)="changeValue($event)">
        <ng-option *ngFor="let item of _data" [value]="item[valueMember]">{{item[displayMember]}}</ng-option>
    </ng-select>
    <span style="padding-right: 5px;padding-top: 5px;cursor: pointer;background: transparent;
        border-radius: 5px;" (click)="openLister()"><i class="simple-icon-magnifier"></i></span>
    <span *ngIf="isButton" style="padding-right: 5px;padding-top: 5px;cursor: pointer;background: #fff;
        border-radius: 5px;" (click)="ButtonClick()"><i class="iconsminds-add"></i></span>
</div>

<template #leatherlookup></template>