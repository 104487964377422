<div [class]="ppdid === 0 ? 'row app-row' : 'row'">
    <div class="col-12 chat-app">
        <div class="d-flex flex-row justify-content-between mb-3" *ngIf="ppdid === 0">
            <div class="d-flex flex-row chat-heading" *ngIf="selectedConversation">
                <a class="d-flex" href="javascript:;">
                    <img src="assets/img/profiles/groupIcon.png"
                        class="img-thumbnail border-0 rounded-circle ml-0 mr-4 list-thumbnail align-self-center small">
                </a>
                <div class=" d-flex min-width-zero">
                    <div
                        class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                        <div class="min-width-zero">
                            <a href="javascript:;">
                                <p class="list-item-heading mb-1 truncate ">
                                    {{ selectedConversation.groupName}}</p>
                            </a>
                            <p class="mb-0 text-muted text-small">{{selectedConversation.createDateTime}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="ppdid === 0" class="separator mb-5"></div>
        <ng-container *ngIf="selectedConversation">
            <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true, wheelPropagation: false}" #scroll>
                <ng-container *ngFor="let m of selectedConversation.message">
                    <div
                        [ngClass]="{'float-right': m.msgBy===currentUserId ,'float-left': m.sender!==currentUserId ,'card d-inline-block mb-3' : true }">
                        <div class="position-absolute pt-1 pr-2 r-0">
                            <span class="text-extra-small text-muted">{{m.date}}</span>
                        </div>
                        <div class="card-body">
                            <div class="d-flex flex-row pb-2">
                                <a class="d-flex" href="javascript:;">
                                    <img src="assets/img/profiles/groupIcon.png"
                                        class="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall">
                                </a>
                                <div class=" d-flex flex-grow-1 min-width-zero">
                                    <div
                                        class="m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                        <div class="min-width-zero">
                                            <p class="mb-0 truncate list-item-heading">{{m.users_Name}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-text-left">
                                <p [id]="m.groupID + m.id" class="mb-0 text-semi-muted">
                                    <button *ngIf="m.isAudio && !getaudioBlobUrl" type="button" (click)="getAudio(m.id)"
                                        class="btn btn-outline-primary icon-button large">
                                        <i style="padding-left: 7px;" class="simple-icon-control-play"></i>
                                    </button>
                                    
                                </p>
                                <p class="mb-0 text-semi-muted">{{m.chatMsg}} </p>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </ng-container>
            </perfect-scrollbar>
        </ng-container>

    </div>
</div>


<div class="chat-input-container d-flex justify-content-between align-items-center">
    <input class="form-control flex-grow-1" type="text" placeholder="Say something..." [(ngModel)]="message"
        (keyup)="messageInputKeyUp($event)">
    <div>
        <button type="button" class="btn btn-outline-primary icon-button large">
            <i class="simple-icon-paper-clip"></i>
        </button>{{' '}}
        <button type="button" class="btn btn-outline-primary icon-button large">
            <i *ngIf="!isAudioRecording && !audioBlobUrl" class="simple-icon-microphone"
                (click)="startAudioRecording()"></i>
            <div id="audioTimer" style="font-size: 12px;" (click)="stopAudioRecording()"
                *ngIf="isAudioRecording && !audioBlobUrl"> {{
                audioRecordedTime }} </div>
            <i *ngIf="!isAudioRecording && audioBlobUrl" (click)="clearAudioRecordedData()"
                class="simple-icon-close"></i>
        </button>{{' '}}
        <button type="button" class="btn btn-primary icon-button large" (click)="sendMessage()">
            <i class="simple-icon-arrow-right"></i>
        </button>
    </div>
</div>




<app-application-menu *ngIf="ppdid === 0">
    <tabset class="card-tabs h-100" [justified]="true">
        <tab [heading]="'chat.groups' | translate" class="card-body h-100">
            <div class="chat-app-tab-pane-messages">

                <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true, wheelPropagation: false}">
                    <div class="d-flex flex-row mb-1 border-bottom pb-3 mb-3" *ngFor="let item of grouplist"
                        (click)="selectConversation(item.ppdid)">
                        <a class="d-flex" href="javascript:;">
                            <img src="assets/img/profiles/groupIcon.png"
                                class="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall">
                        </a>
                        <div class="d-flex flex-grow-1 min-width-zero">
                            <div
                                class="pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                <div class="min-width-zero">
                                    <a (click)="changeGroup(item)" href="javascript:;">
                                        <p class=" mb-0 truncate">{{item.groupName}}</p>
                                    </a>
                                    <p class="mb-1 text-muted text-small">{{item.name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </tab>
        <!-- <tab [heading]="'chat.contacts' | translate" class="card-body h-100">
            <div class="form-group">
                <input type="text" class="form-control rounded-100" placeholder="Search" #searchBoxContact
                    id="search-box" (input)="search(searchBoxContact.value)" />
            </div>
            <div class="chat-app-tab-pane-contacts">
                <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true, wheelPropagation: false}">
                    <ng-container *ngIf="searchKeyword===''">
                        <div class="d-flex flex-row mb-3 border-bottom pb-3" *ngFor="let contact of contacts">
                            <a class="d-flex" href="javascript:;">
                                <img [alt]="contact.title" [src]="contact.img"
                                    class="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall">
                            </a>
                            <div class="d-flex flex-grow-1 min-width-zero">
                                <div
                                    class="m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                    <div class="min-width-zero">
                                        <a href="javascript:;">
                                            <p class="mb-0 truncate">{{contact.title}}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="searchKeyword!==''">
                        <div class="d-flex flex-row mb-3 border-bottom pb-3" *ngFor="let contact of contacts$ | async">
                            <a class="d-flex" href="javascript:;">
                                <img [alt]="contact.title" [src]="contact.img"
                                    class="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall">
                            </a>
                            <div class="d-flex flex-grow-1 min-width-zero">
                                <div
                                    class="m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                    <div class="min-width-zero">
                                        <a href="javascript:;">
                                            <p class="mb-0 truncate">{{contact.title}}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </perfect-scrollbar>
            </div>
        </tab> -->
    </tabset>
</app-application-menu>

<simple-notifications></simple-notifications>